import React from "react";
import styles from "../styles/homepage.module.css";
import {Helmet} from "react-helmet";
import { Link } from "gatsby";

export default function HomePage() {

  return (
    <div>
      <Helmet>
        <title>Meet Star Gazers: Best Locations for Stargazing</title>
        <meta name="description" content="Meet Star Gazers consolidates and characterises the best locations for stargazing." />
      </Helmet>
    <div className={styles.landingPageContainer}>
      <div className={styles.homeContent}>
        <img
          src={require('../images/Meet Star Gazers Only Picto.svg')}
          className={styles.msgLogo}
          alt="Meet Star Gazers"
        />
        <h1 className={styles.homeTitle}>Meet Star Gazers</h1>
        <h2 className={styles.homeSubTitle}>Best Locations for Stargazing</h2>
        <h3 className={styles.homeText}>Mobile application for Android & iOS</h3>
      </div>
    </div>
    <div className={styles.appStoreLinks}>
      <a
        href="https://play.google.com/store/apps/details?id=com.meetstargazers.application"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={require('../images/GooglePlayBadge.png')}
          className={styles.googleBadge}
          alt="Download on Google Play"
        />
      </a>
      <a
        href="https://apps.apple.com/us/app/meet-star-gazers/id1496751760?mt=8"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={require('../images/AppStoreWhite.svg')}
          className={styles.appleBadge}
          alt="Download on Apple App Store"
        />
      </a>
    </div>
    <div className={styles.introVideo}>
      <iframe 
        src="https://www.youtube.com/embed/liY0x_9uyUg?controls=0" 
        title="YouTube video player" 
        frameBorder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowFullScreen
        className={styles.presentationVideo}>
      </iframe>
    </div>
    <div className={styles.firstFeature}>
      <img
        src={require('../images/astronomy.svg')}
        className={styles.featurePicture}
        alt="Refractor telescope and planets"
      />
      <h4 className={styles.featureTitle}>Built for Beginners in Astronomy</h4>
      <p className={styles.featureDescription}>Meet Star Gazers will serve you best if you are a beginner in astronomy. I have tried my best to keep the app welcoming to beginners but at same time walk the user towards the scientific fundamentals that influence night sky observation. In my experience, a good location for stargazing can be characterised by the distance from your location, quality of night sky, type of observation a given location is suitable for namely planetary and lunar observation or deep sky observation, accessibility of the location by car, space to park your vehicle and availability of power supply for astrophotography or motorised telescopes and the weather. Meet Star Gazers carries the ambition of providing you the required information without drowning you in the details.</p>
    </div>
    <div className={styles.featureContainer}>
      <img
        src={require('../images/nightsky.svg')}
        className={styles.featurePicture}
        alt="Night Sky"
      />
      <h4 className={styles.featureTitle}>Quality of Night Sky</h4>
      <p className={styles.featureDescription}>The quality of night sky is essentially determined by the level of light pollution at a given location. Light pollution can be quantified with various methods but I have chosen to go with the Bortle scale to make the concept of light pollution simpler for beginners to understand. Each location on Meet Star Gazers has been characterised based on the quality of it's night sky expressed on the Bortle Scale. The level of light pollution also governs the type of observation you can do from a given location. From light polluted urban locations, stargazers can do planetary and lunar observation. On the other hand, less light polluted rural locations offer stargazers the possibility to do deep sky observation as well as planetary and lunar observation. As a consequence, the type of observation each location is suitable for is also provided on Meet Star Gazers. I have partnered with the International Light Pollution Science and Technology Institute to provide users of Meet Star Gazers a light pollution map. An integrated legend provides the correlation between the light pollution map and the Bortle scale in order to simplify the interpretation of this light pollution map for beginners.</p>
    </div>
    <div className={styles.featureContainer}>
      <img
        src={require('../images/carpark.svg')}
        className={styles.featurePicture}
        alt="Car Park"
      />
      <h4 className={styles.featureTitle}>Accessibility, Parking & Power Supply</h4>
      <p className={styles.featureDescription}>When selecting a location for stargazing, practical aspects like it's accessibility by car, space to park your car and availability of power supply are important factors to be taken into consideration. In most cases, amateur astronomers drive to these locations by car to observe the night sky. If the location is not easily accessible by car, they will be discouraged to travel to this location. Moreover, they will not be able to carry their telescopes to this location which presents an additional hindrance to their hobby. By extension, space to park your vehicle close to the location of observation is also very important. This is the reason why car parks are popular amongst amateur astronomers for stargazing. Furthermore, an availability of power supply will make the location attractive for astrophotographers as well as amateur astronomers who possess motorised telescopes. Often amateur astronomers prefer a location that is easily accessible by car, has space to park their car and offers power supply over a location that has a higher quality of night sky but isn't easily accessible by car, doesn't have space to park their car and doesn't offer power supply. Meet Star Gazers indicates whether a given location is easily accessible by car, has space to park and offers power supply or it doesn't. Clearance of the horizon also has an impact on the selection of a location for stargazing. For stargazers in the northern hemisphere, planets are visible on the sourthern horizon, whereas for stargazers in the southern hemisphere, planets are visible on the northern horizon. Meet Star Gazers indicates whether a given location offers good, partial or poor clearance. On Meet Star Gazers, you can also use the satellite view to study the topography of an observing location before travelling there for night sky observation.</p>
    </div>
    <div className={styles.featureContainer}>
      <img
        src={require('../images/weather.svg')}
        className={styles.featurePicture}
        alt="Weather conditions"
      />
      <h4 className={styles.featureTitle}>Weather Conditions for Astronomy</h4>
      <p className={styles.featureDescription}>Weather conditions play an important role in astronomical observations. But as a beginner, the most important factors I want to know about are cloud cover and felt temperature. Atmospheric turbulence (seeing), transparency, humidity, wind and a few other factors also have an influence on night sky observation but as a beginner I am ready to soldier through bad seeing, high humidity or windy weather as long as the sky is clear and the temperature is bearable for stargazing. I have partnered with Meteoblue which is recognised as the global benchmark in precision weather data services. Meteoblue's weather models were initially developed at the NOAA/NCEP in collaboration with the University of Basel, Switzerland. Since 2006, Meteoblue has been providing reliable weather information to scientists, mountaineers, amateur pilots and astronomers. Meet Star Gazers provides cloud cover and felt temperature forecast for 7 days for all locations that figure on the Meet Star Gazers map. That is not all, thanks to Meteoblue's weather maps service, Meet Star Gazers also provides a visual cloud cover map for 6 days. Meet Star Gazers has you covered in terms of best in class weather forecasts for astronomical observations.</p>
    </div>
    <div className={styles.featureContainer}>
      <img
        src={require('../images/moon.svg')}
        className={styles.featurePicture}
        alt="Lunar ephemerides"
      />
      <h4 className={styles.featureTitle}>Lunar Ephemerides</h4>
      <p className={styles.featureDescription}>The Moon has a significant impact on astronomical observations. If you want to observe or take images of deep sky objects, you will prefer a crescent or new moon. On the other hand, if you love to observe or take images of the Moon, you will prefer to have the Moon present in the night sky. In both cases, knowing the phases of the Moon, moonset and moonrise times is important for stargazers. Meet Star Gazers provides you moonrise and moonset times, moon age, moon phase angle, moon phase, and moon phase transit time information for a month along with sunrise and sunset times. These lunar and solar ephemerides are provided through my partnership with Meteoblue.</p>
    </div>

    <div className={styles.subsidiaries}>
      <a href="https://podcast.meetstargazers.com" target="_blank" rel="noopener noreferrer">
        <button className={styles.subsidiaryButton}>Podcast</button>
      </a>
      <a href="https://blog.meetstargazers.com" target="_blank" rel="noopener noreferrer">
        <button className={styles.subsidiaryButton}>Blog</button>
      </a>
      <a href="https://www.darkskymap.com" target="_blank" rel="noopener noreferrer">
        <button className={styles.subsidiaryButton}>Dark Sky Map</button>
      </a>
    </div>

    <div className={styles.socialMedia}>
      <a href="https://www.facebook.com/MeetStarGazers" target="_blank" rel="noopener noreferrer">
        <img src="https://meetstargazers.s3.eu-west-3.amazonaws.com/images/facebook.svg" alt="Facebook Page" className={styles.handle}/>
      </a>
      <a href="https://twitter.com/MeetStarGazers" target="_blank" rel="noopener noreferrer">
        <img src="https://meetstargazers.s3.eu-west-3.amazonaws.com/images/twitter.svg" alt="Twitter Handle" className={styles.handle}/>
      </a>
      <a href="https://www.instagram.com/meetstargazers" target="_blank" rel="noopener noreferrer">
        <img src="https://meetstargazers.s3.eu-west-3.amazonaws.com/images/instagram.svg" alt="Instagram Handle" className={styles.handle}/>
      </a>
    </div>

    <div className={styles.footerSection}>
      <p className={styles.footerText}>Meet Star Gazers</p>
      <p className={styles.footerText}>Through Meet Star Gazers, my ambition is to make astronomy accessible to beginners. I fell in love with astronomy and I hope you do too. Hopefully, Meet Star Gazers will give you more opportunities to observe the night sky. I have tried my best to simplify stargazing for beginners. If you would like to suggest a location for stargazing, please send me the geographical coordinates of the location by email. If you want to suggest a new feature implementation on Meet Star Gazers, please reach out to me by email.</p>
      <span className={styles.contactElement}>
        <img
          src={require('../images/email.svg')}
          alt="Email"
          className={styles.email}
        />
        <a className={styles.pEmail} href="mailto:contact@darkskymap.com?subject=feedback" target="_blank" rel="noopener noreferrer">contact@darkskymap.com</a>
      </span>
      <Link to="/privacy/" className={styles.privacyLink}>Privacy</Link>
    </div>

    </div>
  );
}
